import { createTheme } from '@mui/material/styles';

const common = {
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    button: {},
    h1: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
      fontSize: '3rem',
      fontWeight: 800,
    },
    h2: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
      fontSize: '2rem',
      fontWeight: 800,
    },
    h3: {
      fontFamily: ['Roboto', 'sans-serif'].join(','),
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h4: {
      fontFamily: ['Roboto', 'sans-serif'].join(','),
      fontSize: '1.3rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: 16,
    },
    body1: {
      fontFamily: ['Roboto', 'sans-serif'].join(','),
      fontSize: '1.1rem',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '10px 30px',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          padding: 8,
          background: 'background.paper',
          borderRadius: 0,
        },
        input: {
          padding: 5,
          height: '1.8rem',
        },
      },
    },
  },
};

export const dark = createTheme({
  palette: {
    mode: 'dark',
    background: {
      paper: '#000f21',
      default: '#000f21',
    },
    primary: {
      main: '#0179bb',
    },
    secondary: {
      main: '#e18e11',
      light: '#fcf3e6',
    },
    grey: {
      A100: '#000f21',
      A200: '#F7F7FA',
    },
  },
  ...common,
});

export const light = createTheme({
  palette: {
    primary: {
      main: '#0179bb',
    },
    secondary: {
      main: '#e18e11',
      light: '#fcf3e6',
    },
    grey: {
      A100: '#000f21',
      A200: '#F7F7FA',
    },
  },
  ...common,
});
