import Wrapper from 'components/wrapper';
import { fetchData } from 'lib/fetchData';
import { markdownToHtml } from 'lib/markdownToHtml';
import type { GetStaticProps, NextPage } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { API } from '_helpers';
import { loadDataGlobal } from '../store/actions/global';
const Banner = dynamic(import('components/banner'));
const AboutUs = dynamic(import('components/aboutUs'));
const Service = dynamic(import('components/service'));
const WorkProcess = dynamic(import('components/workProcess'));
const TechList = dynamic(import('components/technologies'));
const Team = dynamic(import('components/team'));
const Partners = dynamic(import('components/partners'));
const Blog = dynamic(import('components/blog'));
const WhyChooseUs = dynamic(import('components/whyChooseUs'));

interface IProps {
  locale: string;
  banner: any;
  workProcess: any;
  aboutUs: any;
  services: any;
  technology: any;
  partners: any;
  whyChooseUs: any;
  ourTeam: any;
  ourBlog: any;
  menu: any;
  company: any;
  footer: any;
  seo: any;
}

const Home: NextPage<IProps> = (props) => {
  const {
    banner,
    workProcess,
    aboutUs,
    services,
    technology,
    partners,
    whyChooseUs,
    ourTeam,
    ourBlog,
    menu,
    company,
    footer,
    seo,
    locale,
  } = props;
  const dispatch = useDispatch();
  const { asPath } = useRouter();

  React.useEffect(() => {
    dispatch(loadDataGlobal(company));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper menu={menu} company={company} footer={footer}>
      <Head>
        <title>{seo.pages[0].title}</title>
        <meta name="description" content={seo.pages[0].description} />
        <link rel="icon" href={API + company.favicon.media.url} />
        <meta name="robots" content="index,follow" />
        <meta
          property="article:published_time"
          content={new Date().toLocaleTimeString()}
        />
        <meta
          name="publish_date"
          property="og:publish_date"
          content={new Date().toLocaleDateString()}
        />
        <meta
          name="author"
          property="article:author"
          content="Luccin Masirika"
        />
        {/* Open Graph */}
        <meta property="og:title" content={seo.pages[0].title} />
        <meta property="og:description" content={seo.pages[0].description} />
        <meta
          property="og:url"
          content={`https://uptodatedevelopers.com${asPath}`}
        />
        <meta property="og:image" content={API + seo.pages[0].media.url} />
        <meta property="og:site_name" content={company.name} />
        <meta property="og:locale" content={locale} />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={company.name} />
      </Head>
      <Banner data={banner} />
      <WorkProcess data={workProcess} />
      <AboutUs data={aboutUs} />
      <Service data={services} />
      <TechList data={technology} />
      <Partners data={partners} />
      <WhyChooseUs data={whyChooseUs} />
      <Team data={ourTeam} />
      <Blog data={ourBlog} />
    </Wrapper>
  );
};

export const getStaticProps: GetStaticProps = async (context) => {
  const { locale } = context;

  const banner = await fetchData(`/banner/?_locale=${locale}`);
  const workProcess = await fetchData(`/work-process/?_locale=${locale}`);
  const aboutUs = await fetchData(`/short-about-us/?_locale=${locale}`);
  const descriptionAboutUs = await markdownToHtml(aboutUs.description);

  const services = await fetchData(`/short-services/?_locale=${locale}`);
  const technology = await fetchData(`/short-technologies/?_locale=${locale}`);
  const partners = await fetchData(`/our-partners/?_locale=${locale}`);
  const whyChooseUs = await fetchData(`/why-choose-us/?_locale=${locale}`);
  const descriptionWhyChooseUs = await markdownToHtml(whyChooseUs.description);

  const ourTeam = await fetchData(`/our-team/?_locale=${locale}`);
  const ourBlog = await fetchData(`/our-blog/?_locale=${locale}`);

  const menu = await fetchData(`/menu/?_locale=${locale}`);
  const company = await fetchData(`/company/?_locale=${locale}`);
  const footer = await fetchData(`/footer/?_locale=${locale}`);
  const seo = await fetchData(`/seo/?_locale=${locale}`);
  const articles = await fetchData(
    `/articles/?_locale=${locale}&_limit=3&_sort=updatedAt:DESC`
  );

  return {
    props: {
      banner,
      workProcess,
      aboutUs: { ...aboutUs, description: descriptionAboutUs },
      services,
      technology,
      partners,
      whyChooseUs: { ...whyChooseUs, description: descriptionWhyChooseUs },
      ourTeam,
      ourBlog: { ...ourBlog, articles },
      menu,
      company,
      footer,
      seo,
      locale,
    },
    revalidate: 10,
  };
};

export default Home;
