export const cookiesParser = (p: string | undefined) => {
  let c = {};
  const l = p?.split(';');
  l?.map((x) => {
    c = { ...Object.assign({ [x.split('=')[0]]: x.split('=')[1] }) };
  });
  return c;
};

export const API = process.env.NEXT_PUBLIC_API_URL;
