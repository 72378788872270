import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import type { NextPage } from 'next';
import { useDarkMode } from 'next-dark-mode';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import * as React from 'react';
import { API } from '_helpers';
import { dark, light } from '../../utils';
const Footer = dynamic(import('components/footer'));
const Menu = dynamic(import('components/menu'));

interface IProps {
  children: React.ReactElement[] | React.ReactElement;
  menu: IMenu;
  company: ICompany;
  footer: IFooter;
}

const Wrapper: NextPage<IProps> = ({ children, menu, company, footer }) => {
  const { darkModeActive } = useDarkMode();
  const { locale, asPath } = useRouter();
  const mode = darkModeActive ? dark : light;
  const logo = company.logo?.media.url || '';

  return (
    <ThemeProvider theme={mode}>
      <Head>
        <link
          rel="canonical"
          href={`https://uptodatedevelopers.com${asPath}/${locale}`}
        />
        <script type="application/ld+json" id="schema">
          {`
            {
              "@context": "https://schema.org",
            "@type": "Corporation",
            "name": "${company.name}",
            "alternateName": "Updev",
            "url": "https://uptodatedevelopers.com",
            "logo": "${API + logo}",
            "sameAs": [
              "${company.RS?.facebook}",
              "${company.RS?.twitter}",
              "${company.RS?.instagram}",
              "${company.RS?.linkedin}"
            ]
            }
            `}
        </script>
        {/* Global site tag (gtag.js) - Google Analytics */}
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${company.GA_MEASUREMENT_ID}`}
        />
        <script id="google-analytics">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${company.GA_MEASUREMENT_ID}');
        `}
        </script>
      </Head>
      <CssBaseline />
      <Box
        sx={{
          bgcolor: 'background.paper',
          flexGrow: 1,
          maxWidth: 1536,
          margin: 'auto',
        }}
      >
        <Box component="nav">
          <Menu data={menu} logo={company?.logo?.media} />
        </Box>
        <Box component="main">{children}</Box>
        <Box component="footer">
          <Footer company={company} footer={footer} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Wrapper;
